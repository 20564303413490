import React, { Component } from "react";
import "./Login.scss";
import Logo from "asset/logo.png";
import { Redirect, Link } from "react-router-dom";
import CrudMethod from "utils/CrudMethod/callMethod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import PubSub from "pubsub-js";

class Login extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    email: "",
    password: "",
    login: false,
    generalError: "",
    is_staff: false,
    loading: false,
  };

  restorePass = () => {
    this.props.history.push("/restore");
  };

  onChange(e) {
    const { name, value } = e.target;

    this.setState(
      { [name]: value, generalError: "" },
      () => {}
      // console.log(this.state)
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/auth/login/";
    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const postLogin = CrudMethod.requireParams(REACT_APP_BACKEND_URL, resource);
    this.setState({
      generalError: "",
      loading: true,
    });

    postLogin.login(formData).then((res) => {
      // console.log(res.status);
      if (res.status === 200) {
        res.json().then((data) => {
          // console.log("data en login: ", data);

          if (data) {
            localStorage.setItem(
              "loginClient",
              JSON.stringify({
                token: data.token,
                is_staff: data.user.is_staff,
                rol:
                  data.user.is_staff === true
                    ? "9b78fd81-7424-4ffa-ab68-7fc4e0dd9efd"
                    : "",
              })
            );

            this.setState({
              login: true,
              generalError: "",
              is_staff: data.user.is_staff,
            });
            // console.log("estado para login ? ", this.state.is_staff);
          } else {
            // console.log("no data");
          }
        });
      } else if (res.status === 500 || res.status === 400) {
        this.setState({
          login: false,
          is_staff: false,
          email: "",
          password: "",
          generalError: "Error en email o password",
          loading: false,
        });
        console.log("error " + res.status);
      }
    });
  };

  render() {
    const {
      email,
      password,
      login,
      generalError,
      is_staff,
      loading,
    } = this.state;
    return (
      <div className="login-container">
        {!login ? (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  <span>Acceder a Kidsbook </span>
                </div>
                <form onSubmit={this.submitForm} className="form">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Ingrese email"
                      value={email}
                      onChange={this.onChange}
                      required
                      maxLength="128"
                    />
                  </div>

                  <div className="last-item form-group ">
                    <label htmlFor="password">Contraseña</label>
                    <input
                      type="password"
                      name="password"
                      className="input"
                      placeholder="Ingrese Contraseña"
                      value={password}
                      onChange={this.onChange}
                      required
                      maxLength="32"
                    />
                  </div>
                  <div className="message-error">{generalError}</div>

                  <button
                    type="submit"
                    value="Ingresar"
                    className="restore"
                    disabled={loading}
                  >
                    {loading && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Cargando</span>}
                    {!loading && <span>Ingresar</span>}
                  </button>
                </form>
              </div>
              <div className="footer">
                <Link to="/restore"> ¿Olvidaste tu contraseña?</Link>
              </div>
            </div>
          </div>
        ) : is_staff ? (
          <Redirect to="/dashboard"></Redirect>
        ) : (
          <Redirect to="/Login/client"></Redirect>
        )}
      </div>
    );
  }
}

export default Login;
