import React, { Component } from "react";
import Logo from "asset/logo.png";
import { Redirect, Link } from "react-router-dom";
import CrudMethod from "utils/CrudMethod/callMethod";
import "./LoginClientes.scss";
import { SingleCorp } from "utils/configRoute";

class LoginClients extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
    this.onChangeCorp = this.onChangeCorp.bind(this);
    this.onChangeIdRol = this.onChangeIdRol.bind(this);
  }

  state = {
    login: false,
    coporation_id: null,
    idRol: null,
    listCorp: [],
    listRol: [],
    rol: false,
  };

  componentDidMount() {
    const data = JSON.parse(localStorage.getItem("loginClient"));
    const token = data.token;

    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/v1/customer_whitelist/user_customer_whitelist";
    const corpByRol = CrudMethod.requireParams(
      REACT_APP_BACKEND_URL,
      resource,
      token
    );
    corpByRol.get().then((res) => {
      const listCoporationArray = res.json();

      listCoporationArray.then((res) => {
        const corporation = res;
        const listOfcoporation = corporation;
        // console.log("lista de corporaciones: ", listOfcoporation);

        this.setState({
          listCorp: listOfcoporation,
        });
      });
    });
  }

  onChangeCorp(e) {
    const { name, value } = e.target;

    const data = JSON.parse(localStorage.getItem("loginClient"));
    const token = data.token;

    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/v1/role/user_role_list/" + value;
    const getRoles = CrudMethod.requireParams(
      REACT_APP_BACKEND_URL,
      resource,
      token
    );

    getRoles.get().then((res) => {
      const listCoporationArray = res.json();

      listCoporationArray.then((res) => {
        const roles = res;
        const listRoles = roles;
        const idRol = roles.id;
        // console.log("roles aca: ", res);
        // console.log("idRol: ", idRol);

        this.setState({
          listRol: listRoles,
          idRol: idRol,
          rol: true,
        });
      });
    });

    // console.log("el id de la corp: ", value);

    const coporation_id = value;

    this.setState({ coporation_id: coporation_id }, () =>
      console.log(this.state)
    );
  }

  onChangeIdRol(e) {
    const { name, value } = e.target;
    const idRol = value;
    this.setState({ idRol: idRol }, () => console.log(this.state));
  }

  submitForm = (e) => {
    const data = JSON.parse(localStorage.getItem("loginClient"));
    const token = data.token;
    e.preventDefault();
    const { idRol, coporation_id } = this.state;

    // console.log("rol: ", idRol);
    // console.log("id_corp: ", coporation_id);

    if (idRol != null && coporation_id != null && token != null) {
      // console.log("Hola");
      localStorage.setItem(
        "loginClient",
        JSON.stringify({
          rol: idRol,
          corp: coporation_id,
          token: token,
          is_staff: false,

          // quitar para login con roles
          // login:true
        })
      );
      this.setState({
        login: true,
      });
    } else {
      console.log("error en datos ");
    }
  };
  render() {
    const { login, listCorp, listRol, rol } = this.state;
    return (
      <div className="login-container">
        {!login ? (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  <span>Ingresa a: </span>
                </div>
                <form onSubmit={this.submitForm} className="form-client">
                  <div className="double">
                    <label htmlFor="coporation_id">Coporacion</label>
                    <select
                      name="coporation_id"
                      className="input"
                      onChange={this.onChangeCorp}
                    >
                      <option value="" disabled hidden>
                        Selecciona tu coporacion
                      </option>
                      {listCorp.map((data, index) => (
                        <option value={data.corporation} key={index}>
                          {data.corporation_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {rol ? (
                    <div className="double">
                      <label htmlFor="idRol"> Rol en la coporacion </label>
                      <select
                        name="idRol"
                        className="input"
                        onChange={this.onChangeIdRol}
                      >
                        <option value="" disabled hidden>
                          Selecciona tu rol
                        </option>
                        {listRol.map((data, index) => (
                          <option value={data.id} key={index}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <h1 className=""></h1>
                  )}

                  <div className="item-footer">
                    <Link to="/" className="btn back">
                      Volver
                    </Link>

                    <input type="submit" value="Ingresar" className="restore" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to={SingleCorp()}></Redirect>
        )}
      </div>
    );
  }
}

export default LoginClients;
