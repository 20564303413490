const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

let Fn = {
  validaRut: function (rutCompleto) {
    function replaceAll(text, busca, reemplaza) {
      while (text.toString().indexOf(busca) != -1)
        text = text.toString().replace(busca, reemplaza);
      return text;
    }

    let valor = replaceAll(rutCompleto, ".", "");

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(valor)) return false;
    var tmp = valor.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return Fn.dv(rut) == digv;
  },
  dv: function (T) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
};

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    //console.log(val);
    val.length > 0 && (valid = false);
    //console.log("entre a valor en error mayor que 0");
  });
  Object.values(rest).forEach((val) => {
    //console.log(rest);
    //val === undefined && (valid = false);

    //console.log("entre a valor null");
  });

  //console.log(valid);

  return valid;
};

export default { formValid, emailRegex, Fn };
