import React, { Component } from "react";

import { Link } from "react-router-dom";
import Logo from "../../../asset/logo.png";
import "../Login.scss";
import "./ConfirmUser.sass";

class ConfirmUser extends Component {
  state = {
    redirect: false,
    error: false,
  };

  componentDidMount() {
    const token = this.props.arrayURL[2];

    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/account_confirm_email/";
    const url = REACT_APP_BACKEND_URL + resource + token + "/";

    if (token != null) {
      fetch(url, {
        method: "GET",
      }).then((res) =>
        res
          .json()
          .then(() => {
            if (res.status === 200) {
              this.setState({ redirect: true });
              // console.log("entre en 200");
            }
          })
          .catch(() => {
            this.setState({ error: true });
          })
      );
    }
  }

  render() {
    const { redirect, error } = this.state;

    return (
      <div className="login-container">
        {!redirect ? (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image ">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  {!error ? (
                    <span>Tu cuenta ha sido activada </span>
                  ) : (
                    <span> woops error encontrado </span>
                  )}
                </div>
                <br />
              </div>
            </div>
          </div>
        ) : (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image ">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  <span>Su cuenta se ha activado correctamente </span>
                </div>
                <br />
                <div className="form-botton">
                  <Link to="/" className="restore">
                    Volver al login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ConfirmUser;
