import React, { Component } from "react";
import Logo from "../../../asset/logo.png";
import { Link } from "react-router-dom";
import "./Restore.scss";
import validate from "utils/formValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

class Restore extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    email: "",
    redirect: false,
    formErrors: {
      email: "",
    },
    generalError: "",
    loading: false,
  };

  onChange(e) {
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "email":
        formErrors.email = validate.emailRegex.test(value)
          ? ""
          : "Email invalido";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value, generalError: "" }, () =>
      console.log(this.state)
    );
  }

  submitForm = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/auth/password/reset/";
    const url = REACT_APP_BACKEND_URL + resource;

    let formData = new FormData();
    formData.append("email", email.toLowerCase());
    this.setState({ loading: true });
    fetch(url, {
      method: "POST",
      body: formData,
    }).then((res) =>
      res.json().then(() => {
        if (res.status === 400 || res.status === 404) {
          // console.log("en 400 ");
          this.setState({ email: "", generalError: "Ingreser correo valido" });
          swal("Error", "Correo invalido", "error");
          this.setState({ loading: false });

          // console.log(res.status);
        } else if (res.status === 200) {
          // console.log("entre 200");
          swal("Revisa tu bandeja de entrada!", {
            icon: "success",
          });
          this.setState({ redirect: true, loading: true});
        }
      })
    );
  };
  render() {
    const { email, redirect, formErrors, generalError, loading } = this.state;

    return (
      <div className="login-container">
        {!redirect ? (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  <span>Recuperar Contraseña </span>
                </div>

                <form onSubmit={this.submitForm} className="container-restore">
                  <div className="form-group">
                    <label htmlFor="email">Ingresa tu correo</label>
                    <input
                      className="input"
                      type="text"
                      name="email"
                      placeholder="Ingrese tu email"
                      value={email}
                      onChange={this.onChange}

                    />
                     {generalError.length > 0 && (
                      <span className="errorMessage">
                        {generalError}
                      </span>
                    )}
                  </div>

                  <div className="form-botton" style={{marginTop: "1rem"}}>
                    <Link to="/" className="btn back">
                      Volver
                    </Link>

                    {loading && (
                      <button className="restore">
                        {" "}
                        <FontAwesomeIcon
                          icon={faSpinner}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Cargando{" "}
                      </button>
                    )}
                    {!loading && (
                      <button type="submit" className="restore">
                        Recuperar
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div className="Login">
            <div className="container">
              <div className="content">
                <div className="image ">
                  <img alt="Photos" src={Logo} />
                </div>
                <div className="header">
                  <span>Para restablecer tu contraseña </span>
                </div>
                <p>Revisa tu bandeja de correo electronico.</p>

                <div className="form-botton">
                  <Link to="/" className="restore">
                    Volver
                  </Link>
                </div>
              </div>
            </div>
          </div>
          // <Redirect to="/dashboard"></Redirect>
        )}
      </div>
    );
  }
}

export default Restore;
