const requireParams = (base_url, resource, token) => {
  const url = base_url + resource;
  const { REACT_APP_TOKEN_PREFIX } = process.env;
  const tokenId = REACT_APP_TOKEN_PREFIX + " " + token;

  return {
    get: () =>
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: tokenId,
        },
      }),

    patch: (data) =>
      fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: tokenId,
        },
        body: data,
      }),

    create: (data) =>
      fetch(url, {
        method: "POST",
        headers: {
          Authorization: tokenId,
        },
        body: data,
      }),

    login: (data) =>
      fetch(url, {
        method: "POST",
        body: data,
      }),
    logout: () =>
      fetch(url, {
        method: "POST",
      }).then((res) => res.json()),
  };
};

export default { requireParams };
