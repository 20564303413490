import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../Login/Login.scss";
import Logo from "asset/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./NotFoundError.scss";

class NotFoundError extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading: false,
  };

  restorePass = () => {
    this.history.Push("/");
    this.setState({ loading: true });
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="login-container">
        <div className="Login">
          <div className="container">
            <div className="content">
              <div className="image">
                <img alt="Photos" src={Logo} />
              </div>
              <div className="header">
                <span>404 </span>
              </div>

              <p>Página no encontrada</p>

              <a
                href="/"
                type="submit"
                className="restore-error"
                onClick={this.restorePass}
                disabled={loading}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    style={{ marginRight: "5px" }}
                  />
                )}
                {loading && <span>Volviendo</span>}
                {!loading && <span>Volver al inicio</span>}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundError;
