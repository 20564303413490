import React from "react";
import ContentLoader from "react-content-loader";

const DevtoCard = (props) => (
  <ContentLoader
    viewBox="0 0 1200 1200"
    height={1600}
    width={1600}
    speed={2}
    {...props}
  >
    <circle cx="37" cy="28" r="32" />

    <rect x="80" y="-15" rx="2" ry="2" width="3000" height="70" />
    <rect x="-323" y="70" rx="2" ry="2" width="400" height="3168" />
    <rect x="90" y="70" rx="2" ry="2" width="1050" height="600" />
  </ContentLoader>
);

DevtoCard.metadata = {
  name: "RoyalBhati",
  github: "royalbhati",
  description: "Dev.to card",
  filename: "DevtoCard",
};

export default DevtoCard;
