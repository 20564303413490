import React from "react";
import { Redirect } from "react-router-dom";
import Login from "../../Components/Login";


export default function RequiredAuth({ Component }) {
  if (!window.localStorage.getItem("loginClient")) {
    return <Redirect to={Login}></Redirect>;
  } else {
    return <Component></Component>;
  }
}
