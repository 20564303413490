import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./Components/Login";
import Restore from "./Components/Login/Restore";
import NewPassword from "./Components/Login/NewPassword/NewPassword";
import ConfirmUser from "./Components/Login/confirmUser";
import LoginClients from "./Components/Login/LoginClients";
import DevtoCard from "utils/svgLoaders/DevtoCard";
import RequiredAuth from "utils/requiredAuth";
import NotFound from "./Components/Dashboard/NotFound/404";

const Dashboard = React.lazy(() => import("./Components/Dashboard"));

class App extends Component {
  render() {
    return (
      <div>
        {/* {console.log(process.env.REACT_APP_BASE_URL)} */}
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              {({ location }) => {
                const validate = window.localStorage.getItem("loginClient");

                if (!validate) {
                  return <Login />;
                } else {
                  return (
                    <Suspense fallback="">
                      {/*mainSidebar={this.ChangeMainSidebar}*/}
                      <Dashboard />
                    </Suspense>
                  );
                }
              }}
            </Route>

            <Route exact path="/restore">
              {({}) => {
                const validate = window.localStorage.getItem("loginClient");

                if (validate) {
                  return (
                    <Suspense fallback="">
                      {/* aca hay que creear el 404 NO ENCCONTRADO CUANDO SE ESTA LOGEADO */}
                      <NotFound />
                    </Suspense>
                  );
                } else {
                  return <Restore />;
                }
              }}
            </Route>
            <Route path="/recover_password/">
              {({ location }) => {
                localStorage.clear();
                const completeURL = location.pathname,
                  separador = "/",
                  arregloDeURL = completeURL.split(separador);
                // if (location.search)
                //   arregloDeURL[4] = location.search.split("=")[1];
                // console.log(arregloDeURL);

                const validate = window.localStorage.getItem("loginClient");

                if (validate) {
                  return (
                    <Suspense fallback="">
                      {/* aca hay que creear el 404 NO ENCCONTRADO CUANDO SE ESTA LOGEADO */}
                      <NotFound />
                    </Suspense>
                  );
                } else {
                  return <NewPassword arrayURL={arregloDeURL} />;
                }
              }}
            </Route>

            <Route path="/account_confirm_email/">
              {({ location }) => {
                const completeURL = location.pathname,
                  separador = "/",
                  arregloDeURL = completeURL.split(separador);
                // console.log(arregloDeURL);

                return <ConfirmUser arrayURL={arregloDeURL} />;
              }}
            </Route>

            <Route path="/Login/client">
              {() => {
                const data = JSON.parse(localStorage.getItem("loginClient"));
                let token = "";

                if (data === null) {
                  return <Redirect to="/" />;
                } else {
                  token = data.store;
                }

                // console.log("que tenemos aca: ", data.rol);

                if (data.rol.length > 0) {
                  return (
                    <Suspense fallback="">
                      {/* aca hay que creear el 404 NO ENCCONTRADO CUANDO SE ESTA LOGEADO */}
                      <NotFound />
                    </Suspense>
                  );
                } else {
                  return <LoginClients token={token} />;
                }
              }}
            </Route>

            <Route
              path="/dashboard"
              component={(props) => (
                <RequiredAuth {...props} Component={Dashboard} />
              )}
            >
              <Suspense fallback={<DevtoCard />}>
                <Dashboard />
              </Suspense>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
