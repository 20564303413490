import React, { Component } from "react";
import Logo from "../../../asset/logo.png";
import validation from "utils/formValidation";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  state = {
    new_password1: "",
    new_password2: "",
    redirect: false,
    loading: false,
    generalError: "",
    formErrors: {
      new_password1: "",
      new_password2: "",
    },
  };

  onChange(e) {
    const { name, value } = e.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "new_password1":
        formErrors.new_password1 =
          value.length < 5 ? "Ingrese un minimo de 8 caracteres" : "";
        break;
      case "new_password2":
        formErrors.new_password2 =
          value.length < 5 ? "Ingrese un mínimo de 8 caracteres" : "";
        break;

      default:
        break;
    }

    this.setState({ formErrors, [name]: value }, () => console.log(this.state));
  }

  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    // console.log(this.props.arrayURL);
    const { new_password1, new_password2, redirect, formErrors } = this.state;
    const uid = this.props.arrayURL[2];
    const token = this.props.arrayURL[3];
    const origin = this.props.arrayURL[4];
    // if (this.props.arrayURL[4]) origin = this.props.arrayURL[4];

    const { REACT_APP_APODERADO_URL } = process.env;
    const { REACT_APP_EDUCADOR_URL } = process.env;
    const { REACT_APP_ADMIN_URL } = process.env;
    const { REACT_APP_BACKEND_URL } = process.env;
    const resource = "/recover_password/";
    const url =
      REACT_APP_BACKEND_URL + resource + uid + "/" + token + "/" + origin;
    // console.log(origin);
    let formData = new FormData();
    if (new_password1.length > 0) {
      formData.append("new_password1", new_password1);
    } else {
      formErrors.new_password1 = "Ingrese password";
    }

    if (new_password2.length > 0) {
      formData.append("new_password2", new_password2);
    } else {
      formErrors.new_password2 = "Ingrese password";
    }
    formData.append("uid", uid);
    formData.append("token", token);

    if(new_password1 !== new_password2){
      this.setState({
        generalError: "Las contraseñas deben ser iguales",
      });
      swal("Error", "Las contraseñas deben ser iguales", "error");
      this.setState({
        loading: false,
      });
      return;
    }

    if(new_password1.length < 8 ){
      this.setState({
        generalError: "La contraseñas deben tener como mínimo 8 caracteres",
      });
      swal("Error", "La contraseñas deben tener como mínimo 8 caracteres", "error");
      this.setState({
        loading: false,
      });
      return;
    }

    if(!new_password1.match(/[0-9]+/)){
      this.setState({
        generalError: "Por seguridad, la contraseña debe tener al menos un número",
      });
      swal("Error", "Por seguridad, la contraseña debe tener al menos un número", "error");
      this.setState({
        loading: false,
      });
      return;
    }

    if (validation.formValid(this.state)) {
      fetch(url, {
        method: "POST",
        body: formData,
      }).then((res) =>
        res.json().then(() => {
          if (res.status === 400) {
            this.setState({
              generalError: "La contraseña es muy débil. Recuerda que debe tener al menos un número, y no ser parte de tu correo",
            });
            swal("Error", "La contraseña es muy débil. Recuerda que debe tener al menos un número, y no ser parte de tu correo", "error");
            this.setState({ loading: false });
          } else if (res.status === 200) {
            this.setState({ generalError: "" });
            swal("Contraseña actualizada!", {
              icon: "success",
            });
            // console.log("entre 200");
            switch (origin) {
              case "apoderado":
                window.location.replace(REACT_APP_APODERADO_URL);
                break;
              case "educador":
                window.location.replace(REACT_APP_EDUCADOR_URL);
                break;
              case "admin":
                window.location.replace(REACT_APP_ADMIN_URL);
                break;
            }
            this.setState({ redirect: true, loading: true });
          } else if (res.status === 404) {
            swal("Error", "Pagina no encontrada", "error");
            this.setState({ loading: false });
          }
        })
      );
    } else {
      swal(
        "Error",
        "llene los campos para modificar la password: " +
          `
    `,
        "error"
      );

      this.setState({
        loading: false,
      });
      // console.log("form with issues");
    }
  };
  render() {
    const {
      new_password1,
      new_password2,
      redirect,
      formErrors,
      generalError,
      loading,
    } = this.state;
    // console.log(redirect);

    return (
      <div className="login-container">
        {/* {!redirect ? ( */}
        <div className="Login">
          <div className="container">
            <div className="content">
              <div className="image">
                <img alt="Photos" src={Logo} style={{ paddingTop: "12px"}}/>
              </div>
              <div className="header">
                <span>Nueva contraseña </span>
              </div>
              <span style={{fontSize: "80%", paddingTop: "6px", textAlign: "center"}}>Por favor ingresar un mínimo de 8 caracteres entre números y letras.</span>
              <span style={{fontSize: "70%", paddingTop: "8px", textAlign: "center"}}>Contempla que es inseguro que la contraseña contenga parte de tu correo.</span>
              <form onSubmit={this.submitForm} className="form">
                <div className="form-group">
                  <label htmlFor="new_password1">Nueva contraseña</label>
                  <input
                    type="password"
                    name="new_password1"
                    placeholder="Ingrese contraseña"
                    value={new_password1}
                    onChange={this.onChange}
                    maxLength="20"
                  />
                  {formErrors.new_password1.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.new_password1}
                    </span>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="new_password2">Repetir contraseña</label>
                  <input
                    type="password"
                    name="new_password2"
                    placeholder="Ingrese Contraseña"
                    value={new_password2}
                    onChange={this.onChange}
                    maxLength="20"
                  />
                  {formErrors.new_password2.length > 0 && (
                    <span className="errorMessage">
                      {formErrors.new_password2}
                    </span>
                  )}
                </div>

                {loading && (
                  <button className="restore" style={{ marginTop: "1rem" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faSpinner}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Cargando{" "}
                  </button>
                )}
                {!loading && (
                  <button
                    type="submit"
                    className="restore"
                    style={{ marginTop: "1rem" }}
                  >
                    {" "}
                    Guardar
                  </button>
                )}
              </form>
              <div className="message-error">{generalError}</div>
            </div>
            <div className="footer"></div>
          </div>
        </div>
        {/* ) : (
          <Redirect to="/"></Redirect>
        )} */}
      </div>
    );
  }
}

export default NewPassword;
